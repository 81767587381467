<template>

    <div class="container" id="bg-painel">

        <div class="form-login">
            <h3 style="text-align: center">ENC INTERATIVA</h3>
            <form action="#" class="signin-form">
                <div class="form-group mt-3">
                    <label class="form-control-placeholder" for="username">Username</label>
                    <input type="text" class="form-control" required="" v-model="input.email">
                </div>
                <div class="form-group mb-5">
                    <label class="form-control-placeholder" for="password">Password</label>
                    <input id="password-field" type="password" class="form-control" required="" v-model="input.password">
                    <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                </div>

                <button type="button" class="btn btn-primary btn-md btn-block" @click="onSubmit">Submit</button>
            </form>
        </div>
        <b-modal  ref="modalError" id="modalError" hide-footer>
            <p class="my-4">usuário ou senha inválido</p>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Login",
    data() {
        return{
            input: {
                email: null,
                password: null
            }
        }
    },
    methods: {
        onSubmit() {
           
            axios.post("https://www.enchomolog.com.br/painelv3/api/login",this.input)
            .then((response) => {
                localStorage.setItem("token_site",response.data.access_token)
                this.$router.push({name:'painel.taxonomia'})
            }).catch(() => {
               this.$refs.modalError.show()
            })
        
            
        }
    }
}
</script>

<style scoped>
#bg-painel {
    background-color: #F3D62FFF;
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding:20px
}

h2, h3, label {
    color: #fff !important;
}

.form-login {

    padding: 20px;
    width: 450px;
    height: 400px;
    background: #000000;
    border-radius: 5px;
}
</style>
